/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  padding: 0px;
}

* {
  font-family: 'Nunito Sans', sans-serif;
}

.DraftEditor-editorContainer {
  z-index: 0 !important;
}

.gradient-border {
  --border-width: 2px;
  position: relative;
  border-style: inset;
  border-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-width);

  &::after {
    position: absolute;
    content: '';
    top: 0px;
    left: 0px;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: linear-gradient(60deg, hsla(0, 0%, 100%, 1), hsla(0, 0%, 100%, 0.05), hsla(0, 0%, 100%, 0.03));
    background-size: 300% 300%;
    background-position: 0 50%;
    border-radius: calc(2 * var(--border-width));
    animation: moveGradient 4s linear infinite;
  }
}

@keyframes moveGradient {
  25% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}

/* Override default */
.Draftail-Toolbar {
  background: transparent !important;
  border: none !important;
}

/* Override default */
.Draftail-Editor {
  border: none !important;
  width: 60% !important;
  margin-top: 100px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

/* Override default */
.public-DraftEditor-content {
  font-family: Georgia, 'Times New Roman', Times, serif !important;
}

/* Override default */
.public-DraftEditorPlaceholder-inner {
  font-family: Georgia, 'Times New Roman', Times, serif !important;
}
